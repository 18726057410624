<template>
  <div
    class="slider product-slider product-list-slider"
    :class="classes"
    v-if="products && products.length > 0"
  >
    <slot><h2 v-html="title"></h2></slot>
    <slot name="description"><div v-html="description"></div></slot>

    <swiper :options="swiperOption" ref="swiperRef">
      <swiper-slide v-if="hasFirstCardSlot">
        <slot name="firstCard" class="firstCard" />
      </swiper-slide>
      <swiper-slide
        v-for="(product, index) in products"
        :key="product.productId"
      >
        <ProductCardSlider v-bind:product="product" :index="index" />
      </swiper-slide>
    </swiper>
    <swiper-paginator
      :showBullets="showBullets"
      :showArrows="showArrows"
      :paginationClass="paginationClass"
      :length="products.length"
    />
    <div v-if="showMore" class="text-right show-more">
      <v-btn
        link
        text
        class="primary--text text-none "
        :to="
          categoryPath
            ? {
                name: 'Category',
                params: { pathMatch: categoryPath },
                query: { categoryId: categoryId }
              }
            : {
                name: 'Category',
                params: {
                  pathMatch: proposal?.slug
                }
              }
        "
      >
        mostra tutti <v-icon>$chevronRight</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<style global lang="scss">
.show-more {
  .v-btn {
    margin-right: -4px;
  }
}
.product-slider {
  margin-bottom: 16px;
  .proposal-img {
    margin-bottom: 10px;
  }
  .banner-card {
    width: 100%;
    margin-bottom: 20px;
  }
  .proposal-card {
    width: 220px;
    height: 380px;
    .v-card__text {
      width: auto;
    }
    img {
      padding: 0px;
      align-self: center;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .proposal-card {
      height: 285px;
      width: 170px;
      .v-card__text {
        font-size: 11px;
        padding: 0 5px;
      }
    }
  }
  .firstCard {
    float: left;
  }
}
</style>
<script>
import ProductCardSlider from "@/components/product/ProductCardSlider.vue";

import ProductService from "~/service/productService";
import analyticsService from "@/commons/service/analyticsService";
import SwiperPaginator from "../SwiperPaginator.vue";

export default {
  name: "ProductListSlider",
  components: {
    ProductCardSlider,
    SwiperPaginator
  },
  props: {
    productList: { type: Array, required: false },
    query: { type: String, required: false },
    fetchCategoryId: { type: Number, required: false },
    categoryId: { type: Number, required: false },
    categoryPath: { type: String, required: false },
    productId: { type: Number, required: false },
    layout: { type: Number, required: false },
    shuffle: { type: Boolean, required: false, default: false },
    promo: { type: Boolean, required: false, default: false },
    limit: { type: Number, required: false, default: 12 },
    title: { type: String, required: false },
    description: { type: String, required: false },
    proposal: { type: Object, required: false },
    userId: { type: Number, required: false },
    warehouseId: { type: Number, required: false },
    timeslotId: { type: String, required: false },
    paginationClass: { type: String, required: false },
    cols: { default: 1 },
    sm: { default: 2 },
    md: { default: 3 },
    lg: { default: 3 },
    xl: { default: 5 },
    showArrows: { type: Boolean, default: false },
    showBullets: { type: Boolean, default: true }
  },
  data() {
    return {
      products: [],
      showMore: false,
      swiperOption: {
        slidesPerGroup: 1,
        spaceBetween: 20,
        watchOverflow: true,
        autoplay: false,
        pagination: {
          el: `#pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `#prev-${this.paginationClass}`,
          nextEl: `#next-${this.paginationClass}`
        },
        breakpoints: {
          0: {
            slidesPerView: this.cols
          },
          600: {
            slidesPerView: this.sm
          },
          960: {
            slidesPerView: this.md
          },
          1264: {
            slidesPerView: this.lg
          },
          1900: {
            slidesPerView: this.xl
          }
        }
      }
    };
  },
  computed: {
    hasFirstCardSlot() {
      return !!this.$slots.firstCard;
    },
    classes() {
      return [
        "product-list-slider",
        this.cols == "auto" ? "slider-auto" : "product-grid",
        this.$vuetify.breakpoint.smAndDown ? "horizontal" : "vertical"
      ];
    }
  },
  methods: {
    stopAutoplay() {
      this.$refs.swiperRef.swiperInstance.autoplay.stop();
    },
    startAutoplay() {
      if (!this.$refs.swiperRef.swiperInstance.autoplay.running) {
        this.$refs.swiperRef.swiperInstance.autoplay.start();
      }
    },
    handleClick(payload) {
      analyticsService.clickProduct(
        payload.product,
        this.position,
        payload.index
      );
    },
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting) {
        analyticsService.viewProducts(this.products, this.position);
      }
    },
    async fetchProducts() {
      this.products = [];
      if (this.productList) {
        this.products = this.productList;
      } else if (this.query) {
        let response = await ProductService.search({
          q: this.query,
          page_size: 16
        });
        this.products = response.products;
      } else if (this.fetchCategoryId) {
        let response = await ProductService.search({
          parent_category_id: this.fetchCategoryId,
          category_id: this.categoryId,
          page_size: this.limit
        });
        this.products = response.products;
        this.showMore = response.page.totItems > response.page.itemsPerPage;
      } else if (this.proposal) {
        let response = await ProductService.search({
          parent_category_id: this.proposal.categoryProposedId,
          page_size: this.limit
        });
        this.products = response.products;
        this.showMore = response.page.totItems > response.page.itemsPerPage;
      } else {
        let response = await ProductService.adv({
          layout: this.layout,
          product_id: this.productId,
          category_id: this.categoryId,
          limit: this.limit,
          shuffle: this.shuffle,
          promo: this.promo,
          userId: this.userId,
          warehouseId: this.warehouseId,
          timeslotId: this.timeslotId
        });
        if (response) {
          this.products = response.products;
        }
        this.$emit("loaded", this.products ? this.products.length : 0);
      }
    }
  },
  async mounted() {
    // fetch the data when the view is created and the data is
    // already being observed
    await this.fetchProducts();
    if (!this.products || this.products.length < 1) {
      this.$emit("emptyslider");
    }
  }
};
</script>
