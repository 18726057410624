var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.products && _vm.products.length > 0)?_c('div',{staticClass:"slider product-slider product-list-slider",class:_vm.classes},[_vm._t("default",function(){return [_c('h2',{domProps:{"innerHTML":_vm._s(_vm.title)}})]}),_vm._t("description",function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.description)}})]}),_c('swiper',{ref:"swiperRef",attrs:{"options":_vm.swiperOption}},[(_vm.hasFirstCardSlot)?_c('swiper-slide',[_vm._t("firstCard")],2):_vm._e(),_vm._l((_vm.products),function(product,index){return _c('swiper-slide',{key:product.productId},[_c('ProductCardSlider',{attrs:{"product":product,"index":index}})],1)})],2),_c('swiper-paginator',{attrs:{"showBullets":_vm.showBullets,"showArrows":_vm.showArrows,"paginationClass":_vm.paginationClass,"length":_vm.products.length}}),(_vm.showMore)?_c('div',{staticClass:"text-right show-more"},[_c('v-btn',{staticClass:"primary--text text-none",attrs:{"link":"","text":"","to":_vm.categoryPath
          ? {
              name: 'Category',
              params: { pathMatch: _vm.categoryPath },
              query: { categoryId: _vm.categoryId }
            }
          : {
              name: 'Category',
              params: {
                pathMatch: _vm.proposal?.slug
              }
            }}},[_vm._v(" mostra tutti "),_c('v-icon',[_vm._v("$chevronRight")])],1)],1):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }