<template>
  <ProductCard
    v-bind:product="product"
    :position="analiticsIndexPosition + index"
    @selectProduct="
      $emit('selectProduct', {
        product: product,
        index: analiticsIndexPosition + index
      })
    "
  />
</template>
<style lang="scss">
.product-card {
  .product-img {
    width: 170px;
    height: 170px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin: 0px 8px 0px 4px;
      width: 100px;
      height: 100px;
    }
    @media (max-width: 375px) {
      margin: 0px;
    }
  }
  .pulse-enter,
  .pulse-leave {
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
  }
  .actions {
    height: 58px;
    .v-menu__content {
      border-radius: $border-radius-root !important;
    }
    .selectOptions {
      margin: 4px;
    }
  }

  .selectOptionsMenu {
    z-index: 12;
  }
  .iva {
    font-size: 12px;
    white-space: nowrap;
  }
  .price-wraper {
    min-height: 60px;
  }
  .top {
    position: absolute;
    top: 4px;
    right: 4px;
    width: fit-content;
  }

  .actions {
    border-radius: 0px 0px 8px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 60px;
    margin-top: 6px;
    padding: 0px 6px;
  }

  .product-classes-wrapper {
    display: flex;
    margin-top: 5px;
    .product-class .product-class-icon {
      max-width: 25px;
    }
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }
  &.has-promo {
    .product-classes-wrapper {
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        top: 55px;
      }
    }
  }
}
.product-card {
  transition: border-color 0.3s ease;
  &:hover {
    border: 1px solid $primary;
  }
}
</style>
<script>
import productMixin from "~/mixins/product";

import { mapState, mapGetters } from "vuex";

import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import cartService from "~/service/cartService";
import ProductCard from "@/components/product/ProductCard.vue";
export default {
  name: "ProductCardSlider",
  props: {
    product: { type: Object, required: true },
    position: { type: Number, default: undefined },
    analiticsIndexPosition: { type: Number, default: 0 },
    index: { type: Number, required: true }
  },
  components: {
    ProductCard
  },
  mixins: [productMixin],
  data() {
    return {
      selectOptionsArray: [],
      selectedOptions: {},
      // selectablePromo: {},
      componentKey: 0,
      key: 0
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    promoProductClasses() {
      return this.product.productClasses.filter(
        pClass => pClass.productClassGroupId != 10002
      );
    },
    vendor() {
      return this.product.productClasses.find(
        element => element.productClassGroupId == 10002
      );
    },
    item() {
      this.key;
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    },
    promoClass() {
      let productClasses = [];
      let cssClass = get(this.product, "warehousePromo.view.cssClass");
      if (cssClass) {
        productClasses.push("has-promo");
        productClasses.push(cssClass);
      }
      if (this.product.newProduct) {
        productClasses.push("new-product");
      }
      // if (this.product.edge) {
      //   productClasses.push(this.product.badge.cssClass);
      // }
      return productClasses;
    },
    minQuantity() {
      return cartService.plus(this.product);
    }

    // getItemUseWpId() {
    //   let useWpId = null;
    //   if (this.getItem) {
    //     useWpId = get(this.getItem, "cartItemInfo.use_wpid");
    //   }
    //   return useWpId;
    // }
  },
  methods: {
    giftAdded() {
      this.plus();
    },
    onOptionChanged(args) {
      let selectOption = args.selectOption;
      let option = args.option;
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.key;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    },
    handleClick() {
      this.$emit("selectProduct");
    }
  },
  mounted() {
    if (this.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.product.selectOptions);
    }
    // if (this.product?.warehousePromo?.selectable) {
    //   this.selectOptionsArray.push({
    //     name: "Prodotto in scadenza",
    //     parameterName: "use_wpid",
    //     options: [
    //       {
    //         key: undefined,
    //         value: this.$t("product.selectablePromo.fastConsume.promoYes")
    //       },
    //       {
    //         key: this.product.warehousePromo.warehousePromoId,
    //         value: this.$t("product.selectablePromo.fastConsume.promoNo")
    //       }
    //     ]
    //   });
    // }
  }
};
</script>
